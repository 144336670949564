import axios from 'axios';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Box from '@mui/material/Box';

import { useBoolean } from 'src/hooks/use-boolean';

import Main from './main';
import Header from './header';
import NavVertical from './nav-vertical';
import { useRouter } from '../../routes/hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  const router = useRouter();
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getUserToken = async () => {
      if(isAuthenticated){
        const token = await getAccessTokenSilently();
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        console.log(`user token: ${token}`);
        console.log(user);
        const response = await axios.get(`${import.meta.env.VITE_API_URL}/auth`);
        // @ts-ignore
        if(!response.data.includes("admin") && !["kevin.lehtinen@unifymentalhealth.com", "daniel.meas@unifymentalhealth.com", "john.stevens@unifymentalhealth.com"].includes(user?.email)) {
          router.replace("/");
        }
      }
    };
    getUserToken();
  }, [router, user, isAuthenticated, getAccessTokenSilently]);


  const nav = useBoolean();

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {renderNavVertical}
        <Main>{children}</Main>
      </Box>
    </>
  );
}
