import { forwardRef } from 'react';

import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';

import { RouterLink } from 'src/routes/components';


// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    // const theme = useTheme();

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 240,
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <img alt='' src='/assets/images/home/meridian-logo-horizontal-first-black.png'/>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="https://www.meridian-counseling.com/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
